import { apiCall } from "../api";
import { baseUrl } from "../constant/const";

export const mainAction = (url, method='post', data, successCb, failureCb, formData = false, token = "") => async (dispatch) => {

    // const token1 = token || '';
    const csrfToken = '';

    let config = {
        method: method,
        url: `${baseUrl}${url}`,
        headers: {
            Authorization: token ? `Bearer ${token}` : null,
            "Content-Type": formData ? "multipart/form-data" : "application/json",
            'x-csrf-token': csrfToken,
        }
    };

    if (data) {
        config.data = data
    }

    let response = await apiCall(config, dispatch);
    try {
        if (response?.data?.status) {
            successCb(response?.data);
        } else {
            failureCb(response?.data);
        }
    } catch (error) {
        failureCb(response?.data);
    }
};


