import { Avatar, Button, Col, Form, Image, Input, Row, Select } from "antd"
import Typography from "antd/es/typography/Typography"
import { Checkbox, Divider } from 'antd';
import { useEffect, useState } from "react";
import upload from "../../assets/upload.svg"
import { useDispatch } from "react-redux";
import { mainAction } from "../../redux/mainAction";
import { toast } from "react-toastify";
import { AiFillPlusCircle } from "react-icons/ai";
import { FaUpload } from "react-icons/fa";
import AddCarModal from "./AddCarModal";
const ChauffeursForm = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [resume, setResume] = useState({})
    const [coverLatter, setCoverLatter] = useState({})
    const [btnLoding, setBtnLoading] = useState(false)
    const [checkedValue, setCheckedValue] = useState(false)
    const [profilePic, setProfilePic] = useState({})
    const [hasCarClick, setHasCarClick] = useState(false);
    const [loading, setLoading] = useState(false)
    const [dropDwnValue, setDropDwnValue] = useState('')
    const [selectCar, setSelectCar] = useState({})
    const [hasCarId, setHasCarId] = useState("")
    const [operAddCarModal, setOperAddCarModal] = useState(false)

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    })


    const formInputArr = [
        {
            name: 'full_name',
            label: 'Full Name',
            placeholder: 'Type your name here...',
            type: 'text',
            rule: {
                required: true,
                message: 'Please enter your Name!',
            },
            col: 12
        },
        {
            name: 'driver_license',
            label: 'Driver License Type',
            placeholder: 'Type your name here...',
            type: 'selectBox',
            rule: {
                required: true,
                message: 'Please select option!',
            },
            option: [
                {
                    value: '',
                    label: 'Select License Type'
                },
                {
                    value: 'class c',
                    label: "Class C"
                },
                {
                    value: 'class b',
                    label: "Class B"
                },
                {
                    value: 'class a',
                    label: "Class A"
                },
            ],
            col: 12
        },
        {
            name: 'phone_number',
            label: 'Phone Number',
            placeholder: 'Type your Phone number here...',
            type: 'number',
            rule: {
                required: true,
                message: 'Please enter phone number!',
            },
            col: 12
        },
        {
            name: 'email',
            label: 'Email',
            placeholder: 'enter your Email here...',
            type: 'email',
            rule: {
                type: 'email',
                message: 'Please enter a valid email!',
            },
            col: 12
        },
        {
            name: 'city',
            label: 'City',
            placeholder: 'Type your City here...',
            type: 'text',
            rule: {
                required: true,
                message: 'Please enter your city!',
            },
            col: 12
        },
        {
            name: 'zip',
            label: 'Zip',
            placeholder: 'Type your Zip here...',
            type: 'text',
            rule: {
                required: true,
                message: 'Please enter Zipcode!',
            },
            col: 12
        },

    ]

    const onResumeUpload = (e) => {
        const selectedFile = e.target.files[0];
        setResume(selectedFile)
    }
    const onCoverLetterUpload = (e) => {
        const selectedFile = e.target.files[0];
        setCoverLatter(selectedFile)
    }

    const handleCheck = (e) => {
        let value = e.target.checked
        setCheckedValue(value)
    }

    const onFileUpload = (e) => {
        const selectedFile = e.target.files[0];
        e.target.value = ''
        // let url = URL.createObjectURL(selectedFile)
        setProfilePic(selectedFile)
    }

    const submitHandle = (values) => {
        if (checkedValue == true) {
            setBtnLoading(true)
            let formData = new FormData();
            formData.append('profile_pic', profilePic)
            formData.append('full_name', values?.full_name)
            formData.append('license_type', values?.driver_license)
            formData.append('phone_number', values?.phone_number)
            formData.append('email', values?.email)
            formData.append('city', values?.city)
            formData.append('zip', values?.zip)
            formData.append('resume', resume)
            formData.append('cover_letter', coverLatter)
            if (hasCarId) {
                formData.append('hasCar', true)
                formData.append('hasCarId', hasCarId)
            } else {
                formData.append('hasCar', false)
            }
            const successCb = (response) => {
                setBtnLoading(false)
                toast.success(response?.message)
                form.setFieldsValue({
                    full_name: '',
                    driver_license: '',
                    phone_number: '',
                    email: '',
                    city: '',
                    zip: '',
                    hasCar: false
                })
                setResume({})
                setCoverLatter({})
                setProfilePic({})
                setCheckedValue(false)
            }
            const failureCb = (response) => {
                setBtnLoading(false)
                toast.error(response?.message)
            }
            dispatch(mainAction('affiliate/expressDriverAdd', 'post', formData, successCb, failureCb, true))
        } else {
            toast.error("Please accept agreement!")
        }
    }

    const onClickCancelButton = () => {
        console.log("cancell button")
        form.setFieldValue("hasCar", false)
        setHasCarClick(false)
    }
    const onClickSubmitAddCarButton = (res) => {
        console.log("res", res)
        form.setFieldValue("hasCar", true)
        setHasCarId(res?.data?._id);
        setHasCarClick(true)
    }

console.log("hasCarId", hasCarId)


    return (
        <div className="home_formDiv">
            <div className="text-center">
                <h1 className="title_font fw-bold" style={{ color: '#5F92D4' }}>CHAUFFEURS  <span className="title_font fw-bold text-dark"> Accepting Application</span></h1>
            </div>
            <div className="text-center">
                <h6 className="text-center small_font fw-medium">Are you looking to play a part in making a positive impact in your community by advancing the safety in transportation? Apply here & join us.</h6>
                {/* form */}
                <div className="mt-2">
                    <div className="">
                        <input id="fileInput" type="file" accept="image/jpeg,image/png,image/webp,image/jpg" style={{ display: 'none' }} onChange={onFileUpload} />
                        <label htmlFor="fileInput" className="" style={{ cursor: 'pointer' }}>
                            {
                                profilePic?.name ?
                                    // <Image preview={false} src={URL?.createObjectURL(profilePic)} alt="img" className="profile_pic" />
                                    <Avatar src={URL?.createObjectURL(profilePic)} shape="square" size={150} />
                                    :
                                    <>
                                        <div className="profile_pic">
                                            <FaUpload color="#5F92D4" size={35} />
                                            <Typography className="mt-2">Upload</Typography>
                                        </div>
                                    </>
                            }
                        </label>
                        <dt className="mt-2">Upload Profile Pic</dt>
                    </div>
                    <Form name="form" form={form} onFinish={submitHandle} layout="vertical" className="mt-4">
                        <Row gutter={[30, 0]}>
                            {
                                formInputArr?.map((item, indx) => {
                                    let rule = [
                                        item?.rule,

                                    ]
                                    if (item?.type == "email") {
                                        rule.push({
                                            required: true,
                                            message: 'Email is required!',
                                        })
                                    }
                                    if (item?.type === "number") {
                                        rule.push(
                                            {
                                                validator: (_, value) => {
                                                    if (value?.length >= 10) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error("Phone number must be exactly 10 digits!"));
                                                }
                                            },
                                        )
                                    }
                                    // if (item?.type !== 'selectBox') {
                                    //     rule.push({
                                    //         validator: (_, value) => {
                                    //             if (!/\s/g.test(value)) {
                                    //                 return Promise.resolve();
                                    //             }
                                    //             return Promise.reject(new Error("Blank spaces are not allowed.!"));
                                    //         },
                                    //     })
                                    // }
                                    return (
                                        <Col xs={24} sm={24} md={24} lg={item?.col} xl={item?.col} key={indx}>
                                            <Form.Item name={item?.name} label={<Typography className="fw-bold">{item?.label}</Typography>} rules={rule} >
                                                {
                                                    (item?.type !== "selectBox") &&
                                                    <Input className="input_box" bordered={false} style={{ boxShadow: 'none' }} placeholder={item?.placeholder} onKeyPress={(event) => {
                                                        if (item?.type === "number") {
                                                            const keyCode = event.keyCode || event.which;
                                                            const keyValue = String.fromCharCode(keyCode);
                                                            // Allow only numeric characters and control keys
                                                            const regex = /^[0-9\b]+$/;
                                                            if (!regex.test(keyValue) || event.target.value.length >= 10) {
                                                                event.preventDefault();
                                                            }
                                                        }
                                                    }} />
                                                }
                                                {
                                                    item?.type == "selectBox" &&
                                                    <Select
                                                        options={item?.option}
                                                        defaultValue={""}
                                                    />
                                                }

                                            </Form.Item>
                                        </Col>
                                    )
                                })
                            }


                            <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                                {/* <Form.Item name={"resume"} label={<Typography className="fw-bold">Resume</Typography>}  >
                                </Form.Item> */}
                                <Typography className="text-start fw-bold">Resume</Typography>
                                <input id="resumeInput" className="" type="file" bordered={false} style={{ display: 'none' }} onChange={onResumeUpload} />
                                <label htmlFor="resumeInput" className="input_box w-100 mt-2" style={{ borderRadius: 8 }}>
                                    <div className="d-flex align-items-center justify-content-between mt-2 px-2">
                                        <Typography>{resume?.name}</Typography>
                                        <img src={upload} alt="upload_img" />
                                    </div>
                                </label>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} >
                                {/* <Form.Item name={"resume"} label={<Typography className="fw-bold">Resume</Typography>}  >
                                </Form.Item> */}
                                <Typography className="text-start fw-bold marginTop">Cover Letter</Typography>
                                <input id="cover_letter_Input" className="" type="file" bordered={false} style={{ display: 'none' }} onChange={onCoverLetterUpload} />
                                <label htmlFor="cover_letter_Input" className="input_box w-100 mt-2" style={{ borderRadius: 8 }}>
                                    <div className="d-flex align-items-center justify-content-between mt-2 px-2">
                                        <Typography>{coverLatter?.name}</Typography>
                                        <img src={upload} alt="upload_img" />
                                    </div>
                                </label>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item name="hasCar">
                                    <Typography className="text-start fw-bold mt-3">Has Car</Typography>
                                    <Checkbox checked={hasCarClick} value={hasCarClick} onChange={(e) => { if(e.target.checked){setOperAddCarModal(true)};setHasCarClick(e.target.checked)}}>Has Car</Checkbox>
                                </Form.Item>
                                {console.log("hasCarClick", hasCarClick, "hasCarId", hasCarId)}
                                {hasCarClick && (
                                    <AddCarModal modalOpen={operAddCarModal} setModalOpen={setOperAddCarModal} selectCar={selectCar} cancelBtn={onClickCancelButton} submitBtn={onClickSubmitAddCarButton} />
                                )}
                            </Col>
                            <Col lg={24} xl={24}>
                                <Form.Item name='agree' rules={[{ required: true, message: 'Please accept agreement first!' }]} >
                                    <div className="d-flex align-items-center gap-3 mt-3">
                                        <Checkbox checked={checkedValue} className="" onChange={handleCheck} />
                                        <Typography className="" style={{ fontSize: '1rem', textAlign: 'justify' }}>I acknowledge that I have read and understand the Midnght Express Candidate Privacy Statement and all answers provided by me within this application are true and correct.</Typography>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="mt-4">
                            <Button loading={btnLoding} type="ghost" htmlType={checkedValue == true ? 'submit' : 'button'} className="submit_btn">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ChauffeursForm